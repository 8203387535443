import React from "react"
import Layout from "../components/Layout"
import aboutStyle from "./About.module.scss"
import Img from "gatsby-image"
import { graphql } from "gatsby"

export const query = graphql`
  query {
    file(relativePath: { eq: "images/about.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.

        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const About = props => {
  console.log(query)
  return (
    <Layout>
      <h2>My name is Matt</h2>
      <h4>I enjoy simplicity, JavaScript, and sushi. </h4>

      <div className={aboutStyle.main}>
        <div className={aboutStyle.izquierda}>
          <div className={aboutStyle.imagen}>
            {" "}
            <Img
              className={aboutStyle.img}
              fluid={props.data.file.childImageSharp.fluid}
            />
          </div>
          <div className={aboutStyle.texto}>
            <div className={aboutStyle.iconos}>
              <i className="fas fa-map-marker-alt fa-sm"></i>
              <p>NYC, United States</p>
            </div>
            <div className={aboutStyle.iconos}>
              <i className="fas fa-graduation-cap fa-sm"></i>
              <p>Wheaton College</p>
            </div>
            <div className={aboutStyle.iconos}>
              <i className="fas fa-code fa-sm"></i>
              <p>JS, React, C#, Angular, CSS</p>
            </div>
            <div className={aboutStyle.iconos}>
              <i className="fas fa-language fa-sm"></i>
              <p>English y un poco Espanol. </p>
            </div>
          </div>
        </div>
        <div className={aboutStyle.derecha}>
          <p>
            I'm a builder at heart. Growing up it was legos, but through my
            years in college I learned that code was a more enjoyable way to
            build things; it was scalable and the community was awesome. With
            code and web development, I could all of a sudden build out global
            apps using my hands and an internet connection.
          </p>
          <p>
            Currently a consultant based out of NYC, I enjoy tackling business
            problems with code. I'm a full stack developer and my current
            obsession is JavaScript along with React, Redux, and Angular. When
            not coding I enjoy surfing 4 footers on the suprisingly steady surf
            of the Northeast U.S. I also manage the SunOfNYC twitter account,
            which posts a daily status update on how much sunlight we have in
            New York.
          </p>

          <div className={aboutStyle.logoContainer}>
            <p>Some of the tech I like:</p>
            <img
              alt="javascript"
              className={aboutStyle.logo}
              src="./logos/javascript.svg"
            />
            <img
              alt="Gatsby"
              className={aboutStyle.logo}
              src="./logos/gatsby.svg"
            />
            <img
              alt="GraphQl"
              className={aboutStyle.logo}
              src="./logos/graphql.svg"
            />
            <img
              alt="React"
              className={aboutStyle.logo}
              src="./logos/react.svg"
            />
            <img
              alt="nodeJs"
              className={aboutStyle.logo}
              src="./logos/nodejs-icon.svg"
            />
            <img
              alt="Mongo"
              className={aboutStyle.logo}
              src="./logos/mongodb.png"
            />
            <img
              alt="Sass"
              className={aboutStyle.logo}
              src="./logos/sass-1.svg"
            />
          </div>

          <div className={aboutStyle.socialContainer}>
            <p>Send me a message!</p>
            <a
              className={aboutStyle.social}
              href="https://github.com/SirMattCam"
            >
              <i className="fab fa-github"></i>
            </a>
            <a
              className={aboutStyle.social}
              href="https://www.linkedin.com/in/matt--cameron/"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <a
              className={aboutStyle.social}
              href="https://www.instagram.com/SirMattCam/"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              className={aboutStyle.social}
              href="https://twitter.com/Sun_of_NYC"
            >
              <i class="fab fa-twitter"></i>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
