import React from "react"
import "./footer.styles.scss"

const Footer = () => {
  return (
    <div className="footer">
      <p>Matt Cameron © - 2020 </p>
    </div>
  )
}

export default Footer
